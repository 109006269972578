<template>
  <div class="steps-wrap">
    <div
      v-for="(item, i) in steps"
      :key="item"
      class="step"
      :class="i + 1 <= index ? 'active' : ''"
    >
      <div>
        <span>第{{ i + 1 }}步</span>
        <span>{{ item }}</span>
      </div>
      <i
        v-if="i !== steps.length-1"
        class="iconfont icon-a-form-steplink2"
      />
    </div>
    <div
      class="prograss"
      :style="{width: widths[index-1]}"
    />
  </div>
</template>

<script>
  export default {
    props: {
      steps: {
        type: Array,
        default: () => ['实名认证', '关联银行卡', '设置交易密码']
      },
      index: {
        type: Number,
        default: 1
      }
    },
    data() {
      return {
        widths: ['400px', '700px', '100%']
      }
    },
  }
</script>

<style lang="less" scoped>
.steps-wrap {
  width: 100%;
  height: 146px;
  box-shadow: 0px 4px 12px #EDF1F7;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .prograss {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: linear-gradient(109.88deg, #F7D9B7 -3.02%, #EABF96 98.27%);
  }
  .step {
    width: 181px;
    display: flex;
    align-items: center;
    &:nth-child(2){
      margin-left: 48px;
      margin-right: 48px;
    }
    &>div {
      display: flex;
      flex-direction: column;
      width: 150px;
      &>span {
        &:first-child {
          font-weight: bold;
          font-size: 24px;
          color: #8691A8;
        }
        &:last-child {
          font-size: 14px;
          color: #8691A8;
          margin-top: 14px;
        }
      }
    }
    .iconfont {
      font-size: 31px;
      color: #8691A8;
    }
    &.active {
       &>div>span {
        &:first-child {
          color: #CE9B63;
        }
        &:last-child {
          color: #CE9B63;
        }
      }
      .iconfont {
        color: #CE9B63;
      }
    }
  }
}
</style>