<template>
  <div class="acc-wrap">
    <div class="acc-content">
      <steps :index="index" />
      <template v-if="index === 1">
        <div class="step">
          <h3>实名认证</h3>
          <el-form
            ref="step1"
            :model="step1"
            :rules="stpe1Rules"
            label-position="top"
            style="margin-top:44px"
          >
            <el-form-item
              label="身份证验证"
              class="card-item"
              prop="clientName"
            >
              <el-input
                v-model="step1.clientName"
                class="pw-inpput"
                placeholder="请输入您的姓名"
              />
            </el-form-item>
            <el-form-item
              label="身份证号"
              class="card-item"
              prop="idNo"
            >
              <el-input
                v-model="step1.idNo"
                class="pw-inpput"
                placeholder="请输入您的身份证号"
                maxlength="18"
              />
            </el-form-item>
            <el-form-item
              label="证件有效期"
              class="card-item"
              prop="idEnddate"
            >
              <el-select
                v-model="endDate"
                class="select-h50"
                @change="endDateChange"
              >
                <el-option
                  value="1"
                  label="时限有效"
                />
                <el-option
                  value="2"
                  label="长期有效"
                />
              </el-select>
              <el-date-picker
                v-show="endDate === '1'"
                v-model="step1.idEnddate"
                class="pw-inpput"
                style="width:290px !important;margin-left:30px"
                type="date"
                value-format="yyyyMMdd"
                format="yyyy.MM.dd"
                placeholder="请选择证件有效期"
              />
            </el-form-item>
            <el-form-item style="margin-top:44px">
              <span
                class="n-btn"
                @click="handleGoStep2"
              >下一步</span>
              <span
                class="t-btn"
                @click="$router.go(-1)"
              >返回</span>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template v-if="index === 2">
        <div class="step">
          <h3>关联银行卡</h3>
          <el-form
            ref="step2"
            :model="step2"
            :rules="stpe2Rules"
            label-position="top"
            style="margin-top:44px"
          >
            <el-form-item
              label="银行名称"
              class="card-item"
              prop="bankName"
            >
              <el-input
                v-model="step2.bankName"
                class="pw-inpput bank-select"
                suffix-icon="el-icon-arrow-right"
                placeholder="请选择银行卡"
                @focus="handleSelectBank"
              >
                <template slot="prefix">
                  <div class="bank-img_wrap">
                    <img :src="(banklist[selectBankIdx] || {}).logoUrl">
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item
              label="银行卡号"
              class="card-item"
              prop="bankAccount"
            >
              <el-input
                v-model="step2.bankAccount"
                class="pw-inpput"
                placeholder="请输入您的银行卡号"
                maxlength="19"
              />
            </el-form-item>
            <el-form-item
              label="银行预留手机号码"
              class="card-item"
              prop="bankPhone"
            >
              <el-input
                v-model="step2.bankPhone"
                class="pw-inpput"
                placeholder="请输入银行预留手机号码"
                maxlength="11"
              />
            </el-form-item>
            <!-- <el-form-item
              label="图形验证码"
              class="card-item"
              prop="imgCode"
            >
              <el-input
                v-model="step2.imgCode"
                style="width:320px !important"
                class="pw-inpput"
                placeholder="请输入图片中的数字"
              />
              <img
                class="img-code"
                :src="imgCodeInfo.url"
              >
            </el-form-item> -->
            <el-form-item
              label="短信验证码"
              class="card-item"
              prop="smsCode"
            >
              <el-input
                v-model="step2.smsCode"
                placeholder="请输入验证码"
                maxlength="6"
                class="pw-inpput"
                style="width: 270px !important"
              />
              <span
                class="code-b"
                :class="sendCodeDisabled ? 'disabled' : ''"
                @click="sendCode('sendCodeDisabled', 'sendCodeText')"
              >{{ sendCodeText }}</span>
            </el-form-item>
            <el-form-item style="margin-top:44px">
              <span
                class="n-btn"
                @click="handleGoStep3"
              >下一步</span>
              <span
                class="t-btn"
                @click="handleReturn('step2', 1)"
              >返回</span>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <!-- <template v-if="index === 3"> -->
        <div class="step" v-show="index === 3">
          <h3>设置交易密码</h3>
          <el-form
            ref="step3"
            :model="step3"
            :rules="stpe3Rules"
            label-position="top"
            style="margin-top:44px"
            v-show="tradePwdFlag !== '1'"
          >
            <el-form-item
              label="输入交易密码"
              class="card-item"
              prop="tradePassword"
            >
              <el-input
                v-model="step3.tradePassword"
                maxlength="6"
                :type="pwFlag1 ? 'text' : 'password'"
                class="pw-inpput"
                placeholder="6位不连续且不重复的数字"
              >
                <i
                  slot="suffix"
                  class="iconfont"
                  :class="pwFlag1 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                  @click="pwFlag1 = !pwFlag1"
                />
              </el-input>
            </el-form-item>
            <el-form-item
              label="重复交易密码"
              class="card-item"
              prop="tradePasswordCopy"
            >
              <el-input
                v-model="step3.tradePasswordCopy"
                maxlength="6"
                :type="pwFlag2 ? 'text' : 'password'"
                class="pw-inpput"
                placeholder="6位不连续且不重复的数字"
              >
                <i
                  slot="suffix"
                  class="iconfont"
                  :class="pwFlag2 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                  @click="pwFlag2 = !pwFlag2"
                />
              </el-input>
            </el-form-item>
            <el-form-item style="margin-top:44px">
              <span
                class="n-btn"
                @click="handleComplete"
              >完成</span>
              <span
                class="t-btn"
                @click="handleReturn('step3', 2)"
              >返回</span>
            </el-form-item>
          </el-form>


          <el-form
            ref="step4"
            :model="step3"
            :rules="stpe3Rules1"
            label-position="top"
            style="margin-top:44px"
            v-show="tradePwdFlag === '1'"
          >
            <el-form-item
              label="重复交易密码"
              class="card-item"
              prop="tradePasswordCopy"
            >
              <el-input
                v-model="step3.tradePasswordCopy"
                maxlength="6"
                :type="pwFlag2 ? 'text' : 'password'"
                class="pw-inpput"
                placeholder="6位不连续且不重复的数字"
              >
                <i
                  slot="suffix"
                  class="iconfont"
                  :class="pwFlag2 ? 'icon-home-eyes-open' : 'icon-home-eyes-close'"
                  @click="pwFlag2 = !pwFlag2"
                />
              </el-input>
            </el-form-item>
            <el-form-item style="margin-top:44px">
              <span
                class="n-btn"
                @click="handleComplete"
              >完成</span>
              <span
                class="t-btn"
                @click="handleReturn('step3', 2)"
              >返回</span>
            </el-form-item>
          </el-form>
        </div>
      <!-- </template> -->

      <zh-dialog
        ref="bank_select_dialog"
        width="1000"
        hieght="652"
        title="选择开户银行"
      >
        <div class="bank-list">
          <div class="list">
            <div
              v-for="(item, i) in banklist"
              :key="item.bankNo"
              class="item"
              :class="selectBankIdx == i ? 'active' : ''"
              @click="banklistItemClick(i)"
            >
              <div class="item-top">
                <img :src="item.logoUrl">
                <span>{{ item.bankName }}</span>
              </div>
              <div class="item-bottom">
                <div>
                  <span>{{ item.singelLimit }}</span>
                  <span>单笔限额</span>
                </div>
                <div>
                  <span>{{ item.singeldayLimit }}</span>
                  <span>单日限额</span>
                </div>
              </div>
              <div class="delta">
                <i class="iconfont icon-common-step-checkmark" />
              </div>
            </div>
          </div>
          <div class="bottom">
            <span
              class="sure-btn"
              @click="handleSureBank"
            >确认选择</span>
          </div>
        </div>
      </zh-dialog>

      <Models
        :btn-text="btnText"
        :showmodel="showmodel"
        @cancels="modelClose('0')"
        @confirms="modelClose('1')"
        @showmodels="showmodel=false"
      >
        <div class="modal-box">
          <div class="title">
            温馨提示
          </div>
          <div class="tips">
            根据国家相关法律法规要求，购买金融理财产品或使用相关服务，需要上传身份证照片并完善职业信息。信息不完整的用户，后续可能限制使用部分金融服务，建议您立即上传。
          </div>
        </div>
      </Models>
    </div>
  </div>
</template>

<script>
import steps from './components/steps'
import Models from "@/components/Models";
import { getPayChannel, getSmsSignCode, verifCodeForBank, openAccount } from '@/api/myAcc'
import { idCardCheck, bankCardCheck, phoneCheck, tradPWCheck1, tradPWCheck2, chineseCheck } from '@/utils/checkReg.js'
// import { graphicVerifCode } from '@/api/commom'
  export default {
    components: {
      steps,
      Models
    },
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入交易密码'));
        } else {
          if (this.step3.tradePasswordCopy !== '') {
            this.$refs.step3.validateField('tradePasswordCopy');
          }
          callback();
        }
      };
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入交易密码'));
        } else if (value !== this.step3.tradePassword) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };

      return {
        pwFlag1: false,
        pwFlag2: false,
        endDate: '1',
        step1: {
          clientName: '',
          idNo: '',
          idEnddate: '',
        },
        stpe1Rules: {
          clientName: [
            { required: true, message: '请输入姓名', trigger: 'blur' },
            { pattern: chineseCheck, message: '请输入中文' },
            
          ],
          idNo: [
            { required: true, message: '请输入身份证号', trigger: 'blur' },
            { pattern: idCardCheck, message: '身份证号格式不正确' }
          ],
          idEnddate: [
            { required: true, message: '请选择身份证有效期', trigger: 'blur' }
          ]
        },
        step2: {
          bankName: '',
          bankAccount: '',
          bankPhone: '',
          // imgCode: '',
          smsCode: ''
        },
        stpe2Rules: {
          bankName: [
            { required: true, message: '请选择银行', trigger: 'change' }
          ],
          bankAccount: [
            { required: true, message: '请输入银行卡号', trigger: 'blur' },
            { pattern: bankCardCheck, message: '银行卡号格式不正确' }
          ],
          bankPhone: [
            { required: true, message: '请输入银行预留手机号码', trigger: 'blur' },
            { pattern: phoneCheck, message: '手机号码格式不正确' }
          ],
          // imgCode: [
          //   { required: true, message: '请输入图片验证码', trigger: 'blur' }
          // ],
          smsCode: [
            { required: true, message: '请输入短信验证码', trigger: 'blur' }
          ]
        },

        step3: {
          tradePassword: '',
          tradePasswordCopy: ''
        },
        stpe3Rules: {
          tradePassword: [
            { validator: validatePass, trigger: 'blur' },
            { required: true, message: '请输入交易密码', trigger: 'blur' },
            { pattern: tradPWCheck1, message: '密码格式不正确' },
            { pattern: tradPWCheck2, message: '密码格式不正确' }
          ],
          tradePasswordCopy: [
            { validator: validatePass2, trigger: 'blur' },
            { required: true, message: '请再次输入交易密码', trigger: 'blur' },
            { pattern: tradPWCheck1, message: '密码格式不正确' },
            { pattern: tradPWCheck2, message: '密码格式不正确' }
          ],
        },
        stpe3Rules1: {
          tradePasswordCopy: [
            // { validator: validatePass2, trigger: 'blur' },
            { required: true, message: '请再次输入交易密码', trigger: 'blur' },
            { pattern: tradPWCheck1, message: '密码格式不正确' },
            { pattern: tradPWCheck2, message: '密码格式不正确' }
          ],
        },

        banklist: [],
        selectBankIdx: 0,
        showmodel: false,
        btnText: {
          cancel: "稍后补充",
          comfirm: "立即完善",
        },
        index: 0,
        value1: '',
        sendCodeDisabled: false,
        sendCodeText: '获取验证码',
        imgCodeInfo: {},
        tradePwdFlag: '0'
      }
    },
    created () {
      const userInfo = JSON.parse(localStorage.getItem('customerInfo'))
      if (userInfo.authFlag !== '1') {
        this.index = 1
      } else {
        this.index = 3
        this.tradePwdFlag = userInfo.tradePwdFlag
      }
      getPayChannel().then(res => {
        this.banklist = res.data
        this.step2.bankName = this.banklist[0].bankName
      });
    },
    methods: {
      // getImgCode() {
      //   graphicVerifCode().then(res => {
      //     this.imgCodeInfo = res.data
      //   })
      // },
      handleReturn(ref, index) {
        if (index === 2) {
          this.$router.go(-1)
          return
        }
        this.index = index
        this.$refs[ref].resetFields()
      },
      banklistItemClick(idx) {
        this.selectBankIdx = idx
        this.step2.bankName = this.banklist[idx].bankName
      },
      endDateChange(val) {
        if (val === '2') {
          this.step1.idEnddate = '99991231'
        } else {
          this.step1.idEnddate = ''
        }
      },
      modelClose(val) {
        this.showmodel = false
        if(val === '1') {
          this.$router.push({
            name: 'PersonalDataSupply'
          })
        } else {
          this.$router.push('/')
        }
      },
      sendCode(disabled, text)  {
        if (this[disabled]) {
          return
        }
        if (!bankCardCheck.test(this.step2.bankAccount)) {
          this.$message({
            message: '银行卡号格式不正确',
            type: 'warning'
          });
          return
        }
        if (!phoneCheck.test(this.step2.bankPhone)) {
          this.$message({
            message: '请输入银行预留手机号码',
            type: 'warning'
          });
          return
        }
        this[disabled] = true
        this[text] = `发送中...`
        getSmsSignCode({
          clientName: this.step1.clientName,
          idKindGb: "0",
          idNo: this.step1.idNo,
          idEnddate: this.step1.idEnddate,
          bankAccount: this.step2.bankAccount,
          bankPhone: this.step2.bankPhone,
          bankNo: this.banklist[this.selectBankIdx].bankNo,
          bankName: this.banklist[this.selectBankIdx].bankName,
          capitalMode: this.banklist[this.selectBankIdx].capitalMode,
          addDataFlag: '0'
        }).then(() => {
          this.countDown(text, disabled)
        }).catch(() => {
          this[disabled] = false
          this[text] = `获取验证码`
        })
      },
      countDown(text, disabled) {
        let num = 60
        this[text] = `${num}s后重新获取`
        const timer = setInterval(() => {
          num--
          if (num < 1) {
            clearInterval(timer)
            this[text] = `获取验证码`
            this[disabled] = false
          } else {
            this[text] = `${num}s后重新获取`
          }
        }, 1000);
        this.$once('hook:destroyed', () => {
          clearInterval(timer)
        })
      },
      handleSelectBank(e) {
        e.target.blur()
        this.$refs['bank_select_dialog'].open()
      },
      handleSureBank() {
        this.$refs['bank_select_dialog'].close()
      },
      handleGoStep2() {
        this.$refs['step1'].validate((valid) => {
          if (valid) {
            this.index = 2
          }
        })
        
      },
      handleGoStep3() {
        this.$refs['step2'].validate((valid) => {
          if (valid) {
            verifCodeForBank({
              clientName: this.step1.clientName,
              idKindGb: "0",
              idNo: this.step1.idNo,
              idEnddate: this.step1.idEnddate,
              bankAccount: this.step2.bankAccount,
              bankPhone: this.step2.bankPhone,
              bankNo: this.banklist[this.selectBankIdx].bankNo,
              bankName: this.banklist[this.selectBankIdx].bankName,
              capitalMode: this.banklist[this.selectBankIdx].capitalMode,
              addDataFlag: '0',
              bankPhoneCode: this.step2.smsCode
            }).then(() => {
              this.$store.dispatch('GetInfo').then(() => {
                this.index = 3
              })
            })
          }
        })
      },
      handleComplete() {
        if (this.tradePwdFlag === '1') {
          this.$refs['step4'].validate((valid) => {
            if (valid) {
              openAccount({
                tradePassword: this.step3.tradePasswordCopy,
                // tradePasswordCopy: this.step3.tradePasswordCopy,
                clientSource: '1'
              }).then(() => {
                this.$store.dispatch('GetInfo').then(() => {
                  this.showmodel = true
                })
              })
            }
          })
          
        } else {
          this.$refs['step3'].validate((valid) => {
            if (valid) {
              openAccount({
                tradePassword: this.step3.tradePassword,
                tradePasswordCopy: this.step3.tradePasswordCopy,
                clientSource: '1'
              }).then(() => {
                this.$store.dispatch('GetInfo').then(() => {
                  this.showmodel = true
                })
              })
            }
          })
        }
      }
    },
  }
</script>

<style lang="less" scoped>
  @import url('@/assets/css/zc-common.less');
  .acc-wrap {
    width: 100%;
    background-color: #F3F4F6;
    padding: 35px 0;
    .acc-content {
      width: 1200px;
      min-height: 900px;
      margin: 0 auto;
      background-color: #fff;
      box-shadow: 0px 4px 12px #EDF1F7;
      display: flex;
      flex-direction: column;
    }
  }
  .step {
    width: 100%;
    flex: 1;
    padding-top: 42px;
    padding-left: 190px;
    padding-right: 490px;
    padding-bottom: 42px;
    &>h3 {
      font-weight: bold;
      font-size: 18px;
      color: #8691A8;
    }
  }
  .n-btn {
    .btn(200px, 50px);
  }
  .t-btn {
    margin-left: 28px;
    font-size: 16px;
    color: #CE9B63;
  }
  .img-code {
    width: 170px;
    height: 50px;
    border: 1px solid #E0E2E8;
    border-radius: 2px;
    margin-left: 30px;
  }
  .code-b {
    .inline-flex(center, center, row);
    width: 155px;
    height: 50px;
    border: 0.6px solid #CE9B63;
    border-radius: 4px;
    color: #CE9B63;
    font-size: 18px;
    margin-left: 24px;
    &.disabled {
      border: 0.6px solid #BDC0CB;
      color: #BDC0CB;
    }
  }
  .bank-img_wrap {
    width: 100%;
    height: 100%;
    .flex(center, center, row);
    img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }
  }
  .bank-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 42px;
    .list {
      width: 100%;
      flex: 1;
      padding: 0 30px;
      padding-bottom: 30px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 218px;
        height: 132px;
        padding: 14px;
        margin-top: 12px;
        margin-left: 12px;
        border: 1px solid #E0E2E8;
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
        .delta{
          width: 0;
          height: 0;
          border: 10px solid;
          border-color: #CE9B63 #CE9B63 transparent transparent;
          position: absolute;
          top: 0;
          right: 0;
          display: none;
          .iconfont {
            font-size: 12px;
            color: #fff;
            position: absolute;
            transform: scale(0.55, 0.55);
            top: -9px;
            right: -12px;
          }
        }
        &.active {
          border: 1px solid #CE9B63;
          .delta {
            display: block;
          }
        }
        .item-top {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 14px;
          border-bottom: 1px solid #EDF1F7;
          &>img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
          &>span {
            font-size: 18px;
            font-weight: bold;
            color: #25293D;
            margin-left: 20px;
          }
        }
        .item-bottom {
          width: 100%;
          padding-top: 10px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            span {
              font-size: 12px;
              &:first-child {
                color: #25293D;
              }
              &:last-child {
                color: #BDC0CB;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
    .bottom {
      margin-top: 32px;
      width: 100%;
      text-align: center;
      .sure-btn {
        .btn(450px, 48px)
      }
    }
  }
  .modal-box {
    width: 100%;
    .title {
      font-size: 19px;
      font-weight: bold;
      color: #000000;
    }
    .tips {
      margin-top: 24px;
      font-size: 16px;
      color: #000000;
      margin-bottom: 60px;
    }
  }
</style>
<style>
.pw-inpput {
  width:100% !important;
  height:50px !important
}
.pw-inpput .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.card-item .el-form-item__label{
  font-size: 16px !important;
  color: #1F1F1F !important;
  padding: 0 !important;
}
.card-item .el-form-item__content {
  display: flex !important;
}
.select-h50 {
  width:200px !important;
  height:50px !important
}
.select-h50 .el-input__inner {
  height:50px !important;
  line-height: 50px !important;
}
.bank-select .el-input__inner{
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>